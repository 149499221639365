@import "../../../colors.scss";

.announcement-text {
    padding: 4px 6px;
    font-size: .9rem;

    &:after {
        color: $gray;
        padding-left: 8px ;
        font-size: small;
        content: '|';
    }
    &:last-child:after {
        content: none;
    }
}