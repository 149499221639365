@import '../../../colors.scss';

.callout-trigger {
    cursor: pointer;
    text-decoration: underline;
    color: $skyblue;
}
.driver-callout-box {
    background-color: $black;
    width: 450px;
    max-width: unset;
    border: 1px solid $dark-gray;
    box-shadow: 0 3px 5px #111;
    
    .callout-container-fluid {
        .close-callout {
            z-index: 1;
            position: absolute;
            right: 3px;
            top: 3px;
            width: 32px;
            height: 32px;

            &:before, &:after {
                position: absolute;
                left: 14px;
                top:3px;
                content: '';
                height: 25px;
                width: 2px;
                background-color: #fff;
              }
              &:before {
                transform: rotate(45deg);
              }
              &:after {
                transform: rotate(-45deg);
              }
        }
        .driver-headshot {
            padding-left: 10px;
        }
        .driver-details {
            padding-left: 10px;
            p {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 3px;
            }
        }
    }
    .arrow {
        &:after {
            border-right-color: $black;
        }
        &:before {
            border-right-color: $dark-gray;
        }
    }
}

@media screen and (max-width: 575px) {
    .driver-callout-box {
        width: 97vw;
    }
}
