@import "./../../colors";

.flag {
    display: inline-block;
    width: 80px;
    height: 20px;
    margin: 2px 0 0 12px;
    transform: skewX(-20deg);
}

.subhead-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100%;
}

.weather-details .weather-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.subhead-container > h6 {
    padding-right:20px;
}

.time-left {
    font-size: 20px;
}

.flag.none {
    border: solid 1px $light-gray;
}

.flag.green {
    background-color: $green;
}

.flag.red {
    background-color: red;
}

.flag.yellow {
    background-color: yellow;
}

.flag.white {
    background-color: white;
}

.flag.purple {
    background-color: purple;
}

.flag.checkered {
    background-image:
            linear-gradient(45deg, white 25%, transparent 25%),
            linear-gradient(-45deg, white 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, white 75%),
            linear-gradient(-45deg, transparent 75%, white 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0;
}

// .flag-status-container > * {
//     vertical-align: top;
//     // padding-left: 15px;
// }

.flag-status-container .flag {
    margin-right: 10px;
}


.show-top-btn {
    margin-top: 10px;
}
.leader-board .show-top-btn {
    margin-right: 15px;

    &.schedule-button {
        border-color: $white;
    }
}

.popover-flag {
    margin-right: 15px;
}

.flags-popover span {
    vertical-align: text-bottom;
}

.leader-board {
    overflow: hidden;
    height: 100%;
}

.leader-board .flag {
    vertical-align: middle;
}

.leader-board .main-title {
    position: absolute;
    top: 15px;
}

.d-inline-block {
    padding-left: 0px;
}
 
.flag-status {
    padding-left: 50px;
}

@media screen and (max-width: 991px) {
    .leader-board .sub-title {
        margin: 0.7rem;
    }
}

.legend {
    cursor: pointer;
    height: 20px;
    padding: 2px 2px 0 4px;
    margin-top: 15px;
    margin-left: 5px;
    margin-right: 15px;
    display: inline-block;
    background-color: $light-gray;
    transform: skewX(-20deg);
    vertical-align: top;
  }
  
  .legend > * {
    color: $black;
    text-align: center;
    transform: skewX(20deg);
  }
  
  .legend-popover {
    padding: 10px;
  }
  
  .legend-popover-item {
    padding-bottom: 10px;
  }
  
  .legend-popover-desc {
    font-size: 1rem;
    margin-left: 20px;
  }
  
  .legend-green {
    color: $green;
  }
  
  .legend-red {
    color: $red;
  }