.home-page-desktop-container {
    padding: 0;
}

.home-page-mobile-container {
    overflow: hidden;
    height: 100%;
}

.home-page-mobile-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.collapsible-social-feed-container {
    padding: 0;
}
