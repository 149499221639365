@import "./../../../colors";

$lighten-black: lighten($black, 4%);

.home-page-desktop-container {
    .leader-board-table {
        margin-bottom: 110px;
    }
}

.pex {
    overflow-x: scroll;
}

.leader-board-table {
    background-color: $black;
    color: $white-smoke;
    cursor: initial;
    text-align: center;
    font-size: 17px;
    overflow: hidden;
    margin-left: 6px;
    min-width: 1200px;
}

.leader-board-table td {
    position: relative;
}

.leader-board-table td label {
    margin-bottom: 0;
}

.leader-board-table .min-best-lap {
    color: $green;
}

.leader-board-table .best-lap-overall {
    color: $red;
}

.leader-board-table .custom-control-inline {
    margin-left: 1rem;
}

.leader-board-table .in-segment {
    border: solid 2px $white-smoke !important;
}

.leader-board-table {
    .segment {
        position: absolute;
        top: -3px;
        right: 3px;
    }

    .lost-position {
        color: $red;
    }

    .gained-position {
        color: $green;
    }

    .improved-segment {
        color: $green;
    }

    .increased-segment {
        color: $red;
    }
}

@media screen and (max-width: 991px) {
    .leader-board-table tr {
        border-bottom: $dark-gray solid 1px;
    }

    .leader-board-table tr:hover td {
        background: $lighten-black
    }
}

@media screen and (min-width: 992px) {
    .leader-board-table tr th:not(:last-child) {
        border-bottom: $dark-gray solid 1px;
    }

    .leader-board-table tr td:not(:last-child) {
        border-bottom: $dark-gray solid 1px;
    }

    .leader-board-table tr:hover td:not(:last-child) {
        background: $lighten-black
    }

    .leader-board-table td {
        padding-right: 0;
        padding-left: 0;
    }

    .leader-board-table th {
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (max-width: 575px) {
    .leader-board-table td {
        padding-right: 0;
        padding-left: 0;
    }

    .leader-board-table th {
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .leader-board-table td {
        padding-right: 0;
        padding-left: 0;
    }

    .leader-board-table th {
        padding-right: 0;
        padding-left: 0;
    }
}