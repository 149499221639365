@import-normalize;

@import "colors";
@import "bootstrap";

@font-face {
  font-family: 'SFTransRoboticsExtendedBoldItalic';
  src: local('SFTransRoboticsExtendedBoldItalic '), url(./assets/fonts/sf-trans-robotics-extended-bold-italic.ttf) format('truetype');
}

@font-face {
  font-family: 'DINCondensedBold';
  font-style: normal;
  font-weight: normal;
  src: local('DIN Condensed Bold'), url('./assets/fonts/din-condensed-bold.woff') format('woff');
}

body {
  margin: 0;
  font-family: "DINCondensedBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: $black;
  color: white;
  /*height: 100vh;*/
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $link;

  &:hover {
    color: $main-blue
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-control-label::before {
  background-color: transparent;
  border: $main-blue solid 1px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $main-blue !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23#{$white-smoke-raw}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.popover {
  background-color: rgba(34, 36, 39, 0.8);
  color: white;
  font-family: "DINCondensedBold";
}
.popover-header {
  background-color: $black;
  border-bottom: none;
}

.popover-body {
  color: white;
}

.bs-popover-right .arrow:after {
  border-right-color: black;
}

.title {
  font-family: 'SFTransRoboticsExtendedBoldItalic';
  font-size: 17px;
}

.sub-title {
  font-size: 20px;
  color: $light-gray
}

.info {
  cursor: pointer;
  width: 23px;
  height: 15px;
  margin-top: 4px;
  margin-left: 5px;
  display: inline-block;
  background-color: $light-gray;
  transform: skewX(-20deg);
  //vertical-align: top;
}

.info > * {
  color: $black;
  text-align: center;
  transform: skewX(20deg);
}

.nav-item {
  font-family: 'SFTransRoboticsExtendedBoldItalic';
  font-size: 17px;
}

.nav-link {
  color: $gray
}

.nav-link:hover {
  border-color: transparent transparent white transparent !important;
  color: white;
}

.nav-item.nav-link.active {
  background-color: transparent;
  color: white;
}

.nav-link.active {
  background-color: black;
  border: none;
  border-bottom: solid 3px;
}

.nav.nav-tabs {
  justify-content: center;
  border-bottom: 1px solid $gray;
}

.btn {
  color: $white-smoke
}

.bike-logos {
  max-height: 25px;
  margin-top: 0px;
}

@media (max-width: 1000px) {
  .bike-logos {
    max-height: 12px;
  }
}