$green: #8BCD4F;
$pink: #FF7186;
$white: #FFFFFF;
$skyblue: #4FBEFF;
$blue: #0172CE;
$main-blue: #0872CE;
$black: #222427;
$light-gray: #C7C7C7;
$gray: #8A8B8C;
$dark-gray: #333333;
$red: red;

$primary: $main-blue;
$link: $skyblue;
$white-smoke: #E9E9E9;

$white-smoke-raw: E9E9E9;


