@import "../../colors.scss";

.announcements {
    position: sticky;
    width: 100%;
    left: 0;
    bottom: 0;
}
.announcements-bar-container {
    background-color: $black;
    .announcements-bar-desktop-container {
        padding: 15px;
        
        .announcements-list-container {
            padding: 0;
        }
    }
    .announcements-list {
        padding: 5px;
        background-color: white;
        color: black;
        white-space: nowrap;
        overflow: hidden;

        .scrollbar-container {
            animation: marquee 25s linear infinite;
        }

    }
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
